import React from "react"

import Layout from '../../components/layout';
import SubCover from '../../components/sub-cover';
import PageContent from '../../components/page-content';

const createTags = (() => {

  const tagPresets = {
    soundtope: '/technology/soundtope/',
    sonicwalk: '/technology/sonicwalk/',
    omnion: '/technology/omnion/',
    mimicafe: '/technology/mimicafe/',
    その他: '/technology/others/',
    WebAudio: '/technology/others/'
  };

  return (...tags) => {

    return (
      <div style={{
        margin: '-17px 0 25px 14px'
      }}>
        {
          tags.map(tag => {
            const url = tagPresets[tag];
            return (
              <div key={tag} style={{
                display: 'inline-block',
                borderRadius: '3px',
                padding: '0px 5px',
                marginRight: '5px',
                backgroundColor: '#eef'
              }}>
                <a href={url}>{tag}</a>
              </div>
            );
          })
        }
      </div>
    );
  };

})();

const Work = props => {
  return (
    <li>
      <h4>
        {
          props.url ? (
            <a href={props.url} target="_blank" rel="noreferrer noopener">
              {props.title}
            </a>
          ) : (
            props.title
          )
        }
      </h4>
      {createTags(...props.tags.split(','))}
      <p style={{
        paddingLeft: '14px'
      }}>
        {props.children}
      </p>
    </li>
  );
};

const WorkImage = props => (
  <img alt="work" style={{ marginRight: '20px', marginBottom: '10px' }} {...props}></img>
);
const noteIframe = {
  border: 0,
  display: "block", 
  maxWidth: "99%", 
  width: "494px",
  padding: "0px", 
  margin: "10px 0px", 
  position: "static", 
  visibility: "visible"
}

export default () => (
  <Layout pageTitle="実績">

    <script async src="https://note.com/scripts/embed.js" charset="utf-8"></script>

    <SubCover
      title="実績"
      caption=""
      image="works-cover.jpg"
    />
    <PageContent>
      <ul>
        <Work
          title="SOMPO美術館　展覧会「北欧の神秘」"
          url="https://www.sompo-museum.org/exhibitions/2023/magic-north/"
          tags="soundtope"
        >
          SOMPO美術館（東京都新宿区、館長:梅本武文）と協業し、展覧会「北欧の神秘」の一部空間において、cotonの独自技術であるsoundtope(サウンドトープ)を用いた音環境の演出を実施しました。<br />
          <br />
          <a href="https://coton.jp/coton_sompo_news0510.pdf" target="_blank" rel="noreferrer noopener">ニュースリリースはこちら。</a><br />
          <br />
          クレジット<br />
        　プロデューサー　　　本多美優(coton)<br />
        　ディレクター　　　　片倉惇(coton)<br />
        　サウンドデザイン　　助川舞(coton)<br />
        　設営　　　　　　　　髙花謙一(invisi),  小田部剛(invisi)
        </Work>
        <Work
          title="ZUKAN MUSEUM GINZA powered by 小学館の図鑑NEO"
          url="https://zukan-museum.com/"
          tags="soundtope"
        >
          <WorkImage src="/zukan-museum/zukan-museum-main-small.jpg" /><br />
          <br />
          デジタルとリアルが融合した空間をめぐりながら、図鑑の中でしか見ることのできなかった生き物たちに出会い、その息吹を感じ、さらにほかの生き物との出会いに歩みを進める、そんな世界に没入できる新感覚の体験型施設です。<br />
          <br />
          各フィールドのBGMをcotonのsoundtopeを使い自動生成にて制作、また、各生物の鳴き声や足音、各フィールドの環境音もsoundtopeを用いて合成しました。<br />
          <br />
          
          クレジット<br />
          <br />
          企画/制作：AID-DCC・Drill<br />
          <br />
          SOUND<br />
          EXECUTIVE SOUND PRODUCER 松尾 謙二郎（invisi Inc.）<br />
          SOUND PRODUCER 菊池 陽介（invisi Inc.）<br />
          SOUND DIRECTOR / UX PLANNER 藤原 惇（フリーランス）<br />
          SOUND DESIGNER / PROGRAMMER 宮本 貴史（coton Inc.）<br />
          SOUND DESIGNER / COMPOSER 高木 公知（invisi Inc.）<br />
          SOUND DESIGNER 山川 祥太（OTOYA Inc.）<br />
          SOUND DESIGNER 野島 健吾（OTOYA Inc.）<br />
          SOUND DESIGNER 佐藤 優介（OTOYA Inc.）<br />
          MIX ENGINEER 菊地 信哉（フリーランス）<br />
          SOUND SYSTEM ENGINEER 濵野 峻行（coton Inc.）<br />
          SOUND SYSTEM CONSTRUCTION 佐々木 祐馬（Acoustic Technical Laboratory）<br />
          SOUND FIELD OPTIMIZATION CONSULTING パイオニア株式会社<br />
          SOUND HARDWERE PROVIDE AND SUPPORT 株式会社ジェネレックジャパン<br />
          
          <iframe className="note-embed" 
            src="https://note.com/embed/notes/n3cd157d08d0b" 
            style={noteIframe} height="230"></iframe>

        </Work>

        <Work
          title="Pokémon WONDER（ポケモンワンダー）"
          url="https://wonder.pokemon.jp/"
          tags="soundtope,omnion"
        >
          <WorkImage src="/poke-logo.jpg" /><br />
          <br />
          自然の中に隠れている「ポケモン」を探す、ネイチャーアドベンチャー『Pokémon WONDER（ポケモンワンダー）』<br />
          各エリアのBGMをcotonのsoundtopeを使い自動生成にて演出、また『ポケットモンスター』シリーズのゲーム音楽をアレンジした楽曲制作と音響生成システムをinvisi/cotonで担当しました。<br />
          <br />
          ロケーションは、よみうりランドの奥深くに位置する、公開されていなかった自然エリア<br />
          約20年間手つかずだった広さ4,500㎡の森の草原から竹林まで、次々と変わる風景をめぐりながら「ポケモン」を探します。<br />
          <br />
          フィールドレコーディングした現地の音、ポケモン出現の気配を感じるサウンド、soundtopeの音楽要素を組み合わせ、時間帯や天候、季節などの条件でリアルタイムに変化するサウンドスケープで体験の没入感を拡張させています。<br />
          <br />
          <WorkImage src="/poke-1.jpg" />
          <WorkImage src="/poke-2.jpg" /><br />
          <br />
          エグゼクティブプロデューサー	松尾 謙二郎（invisi, coton）<br />
          テクニカルディレクター	濵野 峻行（coton）<br />
          プロジェクトマネージャー	畠山 洋一郎/CD HATA（invisi）<br />
          コンポーザー/サウンドデザイン	米田 望（invisi）<br />
          サウンドデザイン	長嶋 海里（coton）<br />
          ポケモンテーマアレンジ制作	高木 公知（invisi）<br />
          エンジニア/マネージャー	里田 旭彦<br />
          ハードウェア	佐々木 祐馬（ATL-KYOEI）<br />
          エンジニア	森本 洋太（coton）<br />
          エンジニア	宮下 恵太（coton）<br />
          アシスタント	宮本 貴史（coton）<br />
          アシスタント	中野 武尊<br />
          <br />
          ポケモンワンダー公式サイト : <a href="https://wonder.pokemon.jp/" target="_blank" rel="noreferrer noopener">https://wonder.pokemon.jp/</a><br />
          ポケモンワンダー予告映像 : <a href="https://youtu.be/ggaLTHqjBCg" target="_blank" rel="noreferrer noopener">https://youtu.be/ggaLTHqjBCg</a>

          <iframe className="note-embed" 
            src="https://note.com/embed/notes/n25dcaa960caa" 
            style={noteIframe} height="230"></iframe>
            
        </Work>

        <Work
          title="AGC×JAID『8.2秒展』（AGC Studio）"
          url="https://www.agc.com/news/detail/1202117_2148.html"
          tags="soundtope"
        >
          トヨタ自動車デザイン×AGCガラスによるインスタレーション作品にsoundtopeを提供、音響空間の形成<br />
          <br />
          <WorkImage src="/agc1.jpg" />
          <WorkImage src="/agc2.jpg" />
        </Work>

        <Work
          title="AGF® Work Design Coffee ブランドサイト内「Work Design Music」"
          url="https://wdc.agf.jp/wdm/"
          tags="soundtope,WebAudio"
        >
          soundtopeによる作業用BGM生成システムの提供<br />
          <br />
          <WorkImage src="/agf1.jpg" />
          <WorkImage src="/agf2.jpg" />

          <iframe className="note-embed" 
            src="https://note.com/embed/notes/ne278e6bbaf4e" 
            style={noteIframe} height="230"></iframe>

        </Work>

        <Work
          title="広告系ウェブ案件　高品質サーバサイド自動ミックスダウン技術提供"
          tags="その他"
        >
        </Work>

        <Work
          title="東京藝術大学 I LOVE YOU プロジェクト「あるく！空間楽器コンサート」"
          url="https://iloveyou.geidai.ac.jp/schedule/21/?fbclid=IwAR2xnLMqGjD6PB7Pi6tMFLmcrUK72t3fxLZCk-PsRekHCbizx5kZ-BN6Zo4"
          tags="sonicwalk"
        >
          sonicwalkを用いた作品制作システムの運営、作品の公開<br />
          → 会期終了後に公開されたレポート記事は<a href="https://iloveyou.geidai.ac.jp/project09/" target="_blank" rel="noreferrer noopener">こちら</a><br />
          <br />
          <WorkImage src="/aruku-geidai.jpg" />

          <iframe className="note-embed" 
            src="https://note.com/embed/notes/nb92b38cbe334" 
            style={noteIframe} height="230"></iframe>


        </Work>

        <Work
          title="SHISEIDO GLOBAL FLAGSHIP STORE"
          url="https://www.shiseido.co.jp/ginza"
          tags="soundtope"
        >
          soundtopeをベースとした環境音生成と運用システムの開発<br />
          ・作曲／音楽監修：蓮沼 執太 <br />
          ・テクニカルディレクター： 徳井 直生（株式会社Qosmo） 小野寺 唯（株式会社インビジ） <br />
          ・エンジニア： 大石 桂誉（シグナル・コンポーズ株式会社） 余湖 雄一　濵野 峻行（株式会社coton） 宮本 貴史（株式会社coton 森本 洋太(株式会社coton) <br />
          ・プロジェクトマネージャ： 大和 比呂志（シグナル・コンポーズ株式会社）<br />
          <br />
          <WorkImage src="/shiseido1.jpeg" />
          <WorkImage src="/shiseido2.jpeg" />
        </Work>

        <Work
          title="Nスポ！2019 －SHIBUYA－ 「世界を応援しよう！」"
          tags="その他"
        >
          （音響システム協力）
        </Work>

        <Work
          title="KDDI 渋谷エンタメテックプロジェクト AR サービス「Audio Scape」"
          url="https://www.au.com/5g/report/article_7_entertainment/"
          tags="soundtope"
        >
          弊社開発AI音楽生成サービス“soundtope”を提供
        </Work>

      </ul>
    </PageContent>
  </Layout>
);
